import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    // background-color: ${({ theme }) => theme.colors.background};
    background: linear-gradient(310deg, #ff8b00 0%, #ff0100 100%);

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
